import React from "react"
import { SafeArea } from "../SafeArea"
import {
  Root,
  Slice,
  Container,
  PrivacyContainer,
  Header,
  TextHeader,
  Subheader,
  TextContainer,
} from "./styled"

type PrivacyProps = {}

export const Privacy: React.FC<PrivacyProps> = props => {
  return (
    <Root flexWrap="wrap" flex={1}>
      <Slice />
      <SafeArea>
        <Container
          height="100%"
          flexWrap="wrap"
          justifyContent="left"
          alignItems="left"
        >
          <Header>
            <TextHeader>Slalom SparkThink Privacy Policy</TextHeader>
          </Header>
          <PrivacyContainer>
            <TextContainer>
              <p>
                This privacy policy (<b>"SparkThink Privacy Policy"</b>) is
                issued by Slalom, LLC, dba Slalom Consulting, and its
                subsidiaries (<b>"Slalom"</b>, <b>"we"</b> or
                <b>"us"</b>) with respect to the SparkThink survey tool (
                <b>"SparkThink"</b>).
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                This policy (together with our{" "}
                <a href="https://www.slalom.com/cookie-policy">
                  Cookies Policy
                </a>{" "}
                and any other documents referred to on it) sets out the basis on
                which any personal data we collect from you through SparkThink
                when Slalom conducts a survey through SparkThink, or that you
                provide to Slalom through SparkThink when responding to a survey
                from Slalom, will be processed by us.{" "}
                <b>
                  This policy does <u>not</u> cover any personal data we collect
                  offline, or through other online sites (such as our website,{" "}
                  <a href="https://www.slalom.com">www.slalom.com</a>). For
                  information on the privacy practices of our clients when we
                  conduct a survey on behalf of a third party, please refer to
                  the privacy policies and notices of the entity that provided
                  you with the survey link (if you did not receive it directly
                  from Slalom), and/or the entity that is specified to be the
                  entity conducting the survey.
                </b>
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                <em>
                  Residents of the European Economic Area, the United Kingdom,
                  or Switzerland, see our EEA+ Supplemental Data Protection Law
                  Disclosures to this policy <a href="#eea">here</a>.
                </em>
              </p>
              <p>
                <em>
                  California residents, see our California Consumer Privacy Act
                  "At-Collection" Notice <a href="#ccpa">here</a>.
                </em>
              </p>
              <p>
                <em>
                  Residents of Mexico, see our Mexico Supplemental Data
                  Protection Law Disclosures to this policy{" "}
                  <a href="#msdp">here</a>.
                </em>
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                Please read the following carefully to understand our views and
                practices regarding your personal data and how we will treat it.
                By using the SparkThink tool, you are accepting and consenting
                to the practices described in this policy.
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                If the survey you receive is being conducted by Slalom, your
                data will be collected and controlled by Slalom, LLC (a US
                limited liability company organized under the laws of the State
                of Colorado and based at 821 2nd Avenue, Suite 1900, Seattle, WA
                98104) who will share that data within the Slalom group and/or
                with a Slalom company to which the SparkThink survey undertaken
                relates ("<b>Relevant Slalom Company"</b>). For example, if you
                complete a UK survey, then your personal data may be shared with
                Slalom Consulting Limited (a UK incorporate company) for these
                purposes. In such event both Slalom, LLC and the Relevant Slalom
                Company may be considered controllers of your data under
                applicable law (for example, the General Data Protection
                Regulation 2016/679 and its implementing national legislation
                and the Data Protection Act 2018 (the "
                <b>Privacy Legislation</b>")). We and the Relevant Slalom
                Company may also share your personal data with our other
                subsidiaries and within the Slalom group from time to time for
                the purposes set out in this privacy policy.
              </p>
            </TextContainer>
            <Subheader>Information we collect from you</Subheader>
            <TextContainer>
              <p>We will collect and process the following data about you:</p>
              <ul>
                <li>
                  <b>Information you give to us.</b> This is information about
                  you that you give us by responding to a Slalom survey. The
                  information you give us may include your name, address, e-mail
                  address and phone number, and other information that you
                  choose to provide. Providing such personal data is voluntary.
                  However, without providing such personal data, we may not be
                  able to include your responses in our survey or provide you
                  with information or services you request from us. You will
                  know what personal data you provide because you will actively
                  submit it to us, and the personal data will vary depending on
                  the survey that you take using the SparkThink tool.
                </li>
                <li>
                  <b>Information we collect about you.</b> In addition to the
                  personal data that you actively provide, for Slalom to monitor
                  and maintain the performance of the SparkThink tool and to
                  analyze trends, usage and activities in connection with the
                  sites, SparkThink may automatically collect, process and store
                  certain information from you:
                  <ul>
                    <li>
                      technical information, including the domain and host name
                      from which you access the Internet, the Internet protocol
                      (IP) address used to connect your computer to the
                      Internet, browser type and version, time zone and country
                      setting, browser plug-in types and versions, operating
                      system and platform; and
                    </li>
                    <li>
                      information about your visit, including the full Uniform
                      Resource Locators (URL), clickstream to, through and from
                      our site (including date and time), services you viewed or
                      searched for, page response times, download errors, length
                      of visits to certain pages, page interaction information
                      (such as scrolling, clicks, and mouse-overs), and methods
                      used to browse away from the page.{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Information we receive from other sources.</b> We are
                  working closely with third parties (including, for example,
                  marketing services and recruitment agencies) that may provide
                  us with additional information about you.
                </li>
              </ul>
            </TextContainer>
            <Subheader>Cookies</Subheader>
            <TextContainer>
              <p>
                SparkThink uses cookies to distinguish you from other users of
                SparkThink. This helps us to provide you with a good experience
                when you use SparkThink and also allows us to customize and
                improve SparkThink. For detailed information on the cookies we
                use and the purposes for which we use them see our 
                <a href="https://www.slalom.com/cookie-policy">Cookie Policy</a>
                . Some web browsers include a Do-Not-Track (“DNT”) feature or
                setting you can activate to signal your privacy preference not
                to have data about your online browsing activities monitored and
                collected. You can make certain choices about cookies and
                similar technologies to communicate your choice not to be
                tracked online in our <u>privacy preference center</u>. But we
                do not respond to DNT signals.
              </p>
            </TextContainer>
            <Subheader>Uses made of the information</Subheader>
            <TextContainer>
              <p>We use information held about you in the following ways:</p>
              <ul>
                <li>
                  <b>Information you give to us.</b> We will use this
                  information:{" "}
                  <ul>
                    <li>
                      to carry out our obligations arising from any contracts
                      entered into between you and us and to provide you with
                      the information, products and services that you request
                      from us;
                    </li>
                    <li>
                      to provide you with information about other goods and
                      services we offer that are similar to those that you have
                      already purchased or inquired about;
                    </li>
                    <li>
                      to provide you, or permit selected third parties to
                      provide you, with information about goods or services we
                      feel may interest you. We will only contact you by
                      electronic means (e-mail or SMS) with information about
                      goods and services similar to those which were the subject
                      of a previous sale or negotiations of a sale to you, or
                      where you have consented to this, or where we can
                      otherwise lawfully do so.
                    </li>
                    <li>to notify you about changes to our service; and</li>
                    <li>
                      to ensure that content from SparkThink is presented in the
                      most effective manner for you and for your computer.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Information we collect about you.</b> We will use this
                  information:
                  <ul>
                    <li>
                      To administer SparkThink and for internal operations,
                      including troubleshooting, data analysis, testing,
                      research, statistical, and survey purposes;
                    </li>
                    <li>
                      to improve SparkThink to ensure that content is presented
                      in the most effective manner for you and for your
                      computer;
                    </li>
                    <li>
                      as part of our effort to keep SparkThink safe and secure;
                      and
                    </li>
                    <li>
                      to make suggestions and recommendations to you and other
                      users of SparkThink about goods or services that may
                      interest you or them.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Information we receive from other sources.</b> We will
                  combine this information with information you give to us and
                  information we collect about you. We will use this information
                  and the combined information for the purposes set out above
                  (depending on the types of information we receive).
                </li>
              </ul>
            </TextContainer>
            <Subheader>Disclosure of your information</Subheader>
            <TextContainer>
              <p>
                You agree that we have the right to share your personal
                information with:{" "}
              </p>
              <ul>
                <li>Any member of our group, which means our subsidiaries.</li>
                <li>
                  Selected third parties including:
                  <ul>
                    <li>
                      service providers who act as a data processor of Slalom
                      who are subject to contractual obligations to implement
                      technical and organizational security measures to
                      safeguard the personal data and to process the personal
                      data only as instructed. These third-party service
                      providers assist us in assessing our information security
                      controls, maintaining SparkThink, analyzing SparkThink
                      traffic, and in providing services and information to us
                      and to users of SparkThink;
                    </li>
                    <li>
                      analytics and search engine providers that assist us in
                      the improvement and optimization of SparkThink;
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                We will also disclose your personal information to third
                parties:
              </p>
              <ul>
                <li>
                  In the event that we sell or buy any business or assets, in
                  which case we will disclose your personal data to the
                  prospective seller or buyer of such business or assets.
                </li>
                <li>
                  If Slalom, LLC or its subsidiaries, or substantially all of
                  the assets of any of the foregoing, is acquired by a third
                  party, in which case personal data held by it will be one of
                  the transferred assets.
                </li>
                <li>
                  If we are under a duty to disclose or share your personal data
                  in order to comply with any legal obligation, or in order to
                  enforce or apply other agreements; or to protect the rights,
                  property, or safety of Slalom, LLC or its subsidiaries, our
                  employees, customers, or others.
                </li>
              </ul>
            </TextContainer>
            <Subheader>Cross-Border Disclosures</Subheader>
            <TextContainer>
              <p>
                Your personal data may be disclosed to employees,
                representatives, or other third parties operating outside of the
                country you are based who work for, or are engaged by us or our
                subsidiaries in other countries, including the USA and in other
                countries that we operate, and to our service providers who may
                be located outside of the country you are based, including the
                USA and in other countries that we operate. We note that these
                persons and third parties may also store, transfer or access
                personal data outside of the country you are based.
              </p>
              <p>
                In disclosing your personal data overseas, we will take such
                steps as are reasonable in the circumstances to require that
                overseas recipients protect your personal data in accordance
                with applicable laws.
              </p>
            </TextContainer>
            <Subheader>Contact and Complaints</Subheader>
            <TextContainer>
              <p>
                Your personal data may be disclosed to employees,
                representatives, or other third parties operating outside of the
                country you are based who work for, or are engaged by us or our
                subsidiaries in other countries, including the USA and in other
                countries that we operate, and to our service providers who may
                be located outside of the country you are based, including the
                USA and in other countries that we operate. We note that these
                persons and third parties may also store, transfer or access
                personal data outside of the country you are based.
              </p>
              <p>
                In disclosing your personal data overseas, we will take such
                steps as are reasonable in the circumstances to require that
                overseas recipients protect your personal data in accordance
                with applicable laws.
              </p>
              <p>
                Questions, comments or requests regarding this Privacy Policy
                are welcomed and should be addressed to{" "}
                <a href="mailto@privacy@slalom.com">privacy@slalom.com</a> or by
                mail at Slalom, LLC, 821 2nd Avenue, Suite 1900, Seattle, WA
                98104. Similarly, if you have any complaints about our privacy
                management practices, you can contact us at the same details.
                You may also have a right to raise your complaint with the
                relevant privacy regulator in your jurisdiction.
              </p>
            </TextContainer>
            <Subheader>Changes to SparkThink Privacy Policy</Subheader>
            <TextContainer>
              <p>
                We may make changes to this notice, including material changes,
                from time to time. You should review this notice each time you
                use SparkThink to learn of any changes.
              </p>
              <p>Effective Date: August 1, 2022</p>
            </TextContainer>
            <Subheader id="eea">
              EEA+ Supplemental Data Protection Law Disclosures
            </Subheader>
            <TextContainer>
              <p>
                Slalom provides to residents of the European Economic Area, the
                United Kingdom and Switzerland (EEA+) the following disclosures
                to supplement the SparkThink Privacy Policy.
              </p>
              <p>
                Data Controller: The data controller is Slalom with the contact
                information specified above.
              </p>
              <p>
                <u>Legal Bases</u> for Processing under European Union and
                United Kingdom law: Legal bases for the processing of your
                personal data by Slalom are: (i) the contract on the use of
                SparkThink concluded with you (Art. 6 (1) lit. b GDPR) for
                personal data actively provided by you through SparkThink, (ii)
                our legitimate interests (Art. 6 (1) lit. f GDPR) for personal
                data passively collected through SparkThink which are the
                following: to monitor and maintain the performance of SparkThink
                and to analyze trends, usage and activities in connection with
                SparkThink, (iii) our legitimate interests (Art. 6 (1) lit. f
                GDPR) for the transfer of your personal data within the group of
                companies which are the following: for internal administrative
                and support purposes (access is limited to colleagues with a
                need to know), (iv) compliance with a legal obligation to which
                Slalom is subject (Art. 6 (1) lit. c GDPR) for the transmission
                of personal data to law enforcement agencies, governmental
                authorities, legal counsel and external consultants or
                legitimate interests, such as exercise or defense of legal
                claims, (v) our legitimate interests (Art. 6 (1) lit. b GDPR)
                for the transfer of your personal data in connection with a
                transfer of all or part of our organization or assets which are
                the following: the orderly transition of all or part of our
                business, (vi) our legitimate interests to protect and defend
                the rights or property of us or third parties, including
                enforcing agreements, policies and terms of use, and in an
                emergency including to protect the safety of our employees or
                any person which are the following: to protect the property,
                rights, and safety of any person and to prevent fraud.
              </p>
              <p>
                <u>Recipients in Third Countries:</u> The personal data that we
                collect or receive about you may be transferred to and processed
                by recipients that are located inside or outside the EEA+ and
                which are not recognized from an EEA+ law perspective as
                providing for an adequate level of data protection. These third
                parties are generally cloud hosting and internet service
                providers, operating systems and platform providers, and certain{" "}
                <a href="https://www.slalom.com/platforms">
                  technology partners
                </a>
                . When interacting with the SparkThink tool or contacting
                Slalom, LLC, you transfer personal data to the United States,
                and Slalom, LLC may transfer your personal data to the UK,
                Australia, Japan, Canada, Germany, Switzerland, Ireland, Mexico,
                and New Zealand. Transfers from Switzerland to the UK, Germany,
                Canada, New Zealand, and Ireland do not require additional
                safeguards. Transfers from Switzerland to Australia, Japan,
                Mexico and the US are based on your consent. To the extent your
                personal data are transferred to countries that do not provide
                for an adequate level of data protection from an EU or UK law
                perspective, we will base the respective transfer on appropriate
                safeguards (Art. 46 GDPR/UK GDPR), such as standard data
                protection clauses adopted by the European Commission. You can
                ask for a copy of such appropriate safeguards by contacting us
                as set out in the Contact and Complaints section.
              </p>
              <p>
                <u>Period of Data Storage:</u> Your personal data will be
                retained for as long as necessary to provide you with the
                services requested, which in most cases does not exceed 5 years.
                When Slalom no longer needs to use your personal data to comply
                with contractual or statutory obligations, we will remove it
                from our systems and records and/or take steps to properly
                anonymize it so that you can no longer be identified from it,
                unless we need to keep your information, including personal
                data, for tax, audit, and legal compliance for a legally
                prescribed time period thereafter, or if we need it to preserve
                evidence within statutes of limitation.
              </p>
              <p>Data Subject Rights:</p>
              <p>
                Under applicable law, you may have the following rights in
                relation to your personal data. These may be limited under
                applicable data protection law.
              </p>
              <p>
                (i) Right to request from us access to personal data: You have
                the right to confirm with us whether your personal information
                is processed, and if it is, to request access to that personal
                information including the categories of personal information
                processed, the purpose of the processing and the recipients or
                categories of recipients. You have the right to obtain a copy of
                the personal data undergoing processing. However, this is not an
                absolute right and the interests of other individuals may
                restrict your right of access.
              </p>
              <p>
                (ii) Right to rectification: You have the right to obtain from
                us rectification of inaccurate or incomplete personal
                information concerning you. Depending on the purposes of the
                processing, you may have the right to have incomplete personal
                data completed, including by means of providing a supplementary
                statement.
              </p>
              <p>
                (iii) Right to erasure of your personal data (right to be
                forgotten): You have the right to ask us to erase personal data
                concerning you.
              </p>
              <p>
                (iv) Right to restriction of processing: In limited
                circumstances, you have the right to request that we restrict
                processing of your personal data.
              </p>
              <p>
                (v) Right to data portability: You may have the right to receive
                personal data concerning you, which you have provided to us, in
                a structured, commonly used and machine-readable format and you
                may have the right to transmit that personal data to another
                entity without hindrance from us.
              </p>
              <p>
                <b>
                  (vi) Right to object: Under certain circumstances you may have
                  the right to object, on grounds relating to your particular
                  situation, at any time to the processing of your personal
                  data, including profiling, by us and we can be required to no
                  longer process your personal data.
                </b>
              </p>
              <p>
                <b>
                  Moreover, if your personal data is processed for direct
                  marketing purposes, you have the right to object at any time
                  to the processing of personal data concerning you for such
                  marketing, which includes profiling to the extent that it is
                  related to such direct marketing. In this case your personal
                  data will no longer be processed for such purposes by us.
                </b>
                <br />
                Depending on your place of residence, you may also have the
                right to lodge a complaint with a supervisory authority. If you
                have declared your consent for any personal data processing
                activities, you can withdraw this consent at any time with
                future effect. Such a withdrawal will not affect the lawfulness
                of the processing prior to the consent withdrawal. You can
                exercise your rights by contacting us as set out in the Contact
                and Complaints section.
              </p>
              <p>
                <u>Failure to Provide Personal Data: </u>
                Where we are required by law to collect your personal data, or
                we need to collect your personal data under the terms of a
                contract we have with you, and you fail to provide that personal
                data when we request it, we may not be able to perform the
                contract we have or are trying to enter into with you. This may
                apply where you do not provide the personal data we need in
                order to provide the services you have requested from us. In
                this case, we may have to cancel the provision of the relevant
                services to you, in which case we will notify you.
              </p>
              <p>
                For your information:
                <ul>
                  <li>
                    inquiries relating to our European business are generally
                    handled by Slalom Consulting Limited, a company incorporated
                    and registered in England and Wales with company number
                    08874662 whose registered office is at 2 London Bridge, 2nd
                    Floor East, London, SE1 9RA, United Kingdom;
                  </li>
                  <li>
                    inquiries relating to our Canadian business are generally
                    handled by Slalom Consulting ULC, a Canadian unlimited
                    liability company formed under the laws of British Columbia
                    whose registered office is at 745 Thurlow Street, Suite
                    2400, Vancouver, BC V6E 0C5, Canada;
                  </li>
                  <li>
                    inquiries relating to our U.S. and Mexican business are
                    generally handled by Slalom, LLC, a limited liability
                    company organized under the laws of the State of Colorado
                    and based at 821 2nd Avenue, Suite 1900, Seattle, WA 98104,
                    USA;
                  </li>
                  <li>
                    inquiries relating to our Japanese business are generally
                    handled by Slalom Japan Co., Ltd., a Japanese join stock
                    company whose registered office is 7F Atago Green Hills Mori
                    Tower, 2 Chome 5-1 Atago, Minato-ku, Tokyo 105-6207, Japan;
                  </li>
                  <li>
                    inquiries relating to our Australian business are generally
                    handled by Slalom Australia Pty Ltd a company incorporated
                    and registered in Australia with company number 635 861 004
                    whose registered office is at Level 17, 100 Queen Street,
                    Melbourne Victoria 30000, Australia;
                  </li>
                  <li>
                    inquiries relating to our New Zealand business are generally
                    handled by Slalom New Zealand Ltd a company incorporated and
                    registered in New Zealand with company number 8244300 whose
                    registered office is at Level 22 Floor, PWC Tower, 15
                    Customs Street West, Auckland Central, Auckland 1010, New
                    Zealand;
                  </li>
                </ul>
                unless we notify you otherwise.
              </p>
            </TextContainer>
            <Subheader>
              Japan Supplemental Data Protection Law Disclosures
            </Subheader>
            <TextContainer>
              <p>
                <b>1. Details of relevant Slalom entities</b>
              </p>
              <p>
                Address and the name of the company representatives of Slalom
                entities that regularly process personal information of
                individuals in Japan are as follows:
              </p>
              <p>
                Slalom, LLC
                <br />
                821 2nd Avenue, Suite 1900, Seattle, WA 98104
                <br />
                Company representative: Christopher Burger, Vice President,
                Information Security & Governance,{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.
              </p>
              <p>
                Slalom Japan Co., Ltd.
                <br />
                7F Atago Green Hills Mori Tower, 2 Chome 5-1 Atago, Minato-ku
                Tokyo 105-6207, Japan
                <br />
                Company representative: Ko Asami, Representative Director,{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.
              </p>
              <p>
                For details of other entities, please contact us by email
                addressed at{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a> or by
                mail Slalom Consulting, Privacy Office, 821 2nd Avenue, Suite
                1900, Seattle, WA 98104.
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                <b>2. Joint use of personal Data within Slalom group</b>
              </p>
              <p>
                We will share your personal data described in this policy with
                the Slalom entities (please see a list of the Slalom entities
                below). Those Slalom entities will use the personal data for the
                purposes set out in the "Uses made of the information" section.
                The entity which is responsible for management of personal data
                is Slalom Japan Co., Ltd. specified above.
              </p>
              <p>
                <b>Slalom entity list</b>
                <br />
                Slalom, LLC
                <br />
                Slalom Consulting, ULC
                <br />
                Slalom Consulting Ltd.
                <br />
                Slalom Australia Pty Ltd.
                <br />
                Slalom Netherlands B.V.
                <br />
                Slalom GmbH
                <br />
                Slalom Limited
                <br />
                Slalom New Zealand Limited
                <br />
                Slalom Switzerland GmbH
                <br />
                Slalom Technology and Management Solutions Limited
                <br />
                Slalom Consulting Mexico S.R.L. de C.V.
              </p>
            </TextContainer>
            <Subheader id="ccpa">
              California Consumer Privacy Act "At-Collection" Notice
            </Subheader>
            <TextContainer>
              <p>
                In this California Consumer Privacy Act ("<b>CCPA</b>") Notice
                at Collection Online, we are addressing disclosure requirements
                under California Civil Code §1798.100and California Attorney
                General Regulations at or before the point of online collection
                with respect to information collected by Slalom from California
                residents when California residents answer a survey on the
                SparkThink tool that is being conducted by Slalom.{" "}
                <b>
                  This CCPA Notice At Collection Online does not cover any
                  personal data we collect offline or through other online sites
                  (such as our website,{" "}
                  <a href="https://www.slalom.com/">www.slalom.com</a>). For
                  information on the privacy practices of our clients when we
                  conduct a survey on behalf of a third party, please refer to
                  the privacy policies and notices of the entity that provided
                  you with the survey link (if you did not receive it directly
                  from Slalom), and/or the entity that is specified to be the
                  entity conducting the survey.
                </b>
              </p>
              <p>
                We collect the following categories of personal information
                about consumers and for the following business or commercial
                purposes:
              </p>
              <p>
                Information you provide when you respond to a survey through the
                SparkThink survey tool. The information you give us may include
                your name, e-mail address and phone number, and other
                information you choose to provide. Providing such personal data
                is voluntary. However, without providing such personal data, we
                will not be able to provide you with information or services you
                request from us, if any. You will know what personal data you
                provide because you will actively submit it to us, and the
                personal data will vary depending on the survey that you take
                using the SparkThink tool. We use information you actively
                provide to us:
                <ul>
                  <li>
                    to carry out our obligations arising from any contracts
                    entered into between you and us and to provide you with the
                    information, products and services that you request from us;
                  </li>
                  <li>
                    to provide you with information about other goods and
                    services we offer that are similar to those that you have
                    already purchased or inquired about;
                  </li>
                  <li>
                    to provide you, or permit selected third parties to provide
                    you, with information about goods or services we feel may
                    interest you. We will only contact you by electronic means
                    (e-mail or SMS) with information about goods and services
                    similar to those which were the subject of a previous sale
                    or negotiations of a sale to you, or where you have
                    consented to this, or where we can otherwise lawfully do so.
                  </li>
                  <li>to notify you about changes to our service;</li>
                  <li>
                    to ensure that content from SparkThink is presented in the
                    most effective manner for you and for your computer.
                  </li>
                </ul>
              </p>
              <p>
                Consumers who have a visual disability may be able to use a
                screen reader or other text-to-speech or text-to-Braille tool to
                review the contents of this notice. We do not sell your personal
                information or share it for cross‐context behavioral
                advertising.
              </p>
              <p>
                Your personal information will be retained for as long as
                necessary to provide you with the services requested, which in
                most cases does not exceed 5 years. When Slalom no longer needs
                to use your personal data to comply with contractual or
                statutory obligations, we will remove it from our systems and
                records and/or take steps to properly anonymize it so that you
                can no longer be identified from it, unless we need to keep your
                information, including personal data, for tax, audit, and legal
                compliance for a legally prescribed time period thereafter, or
                if we need it to preserve evidence within statutes of
                limitation.
              </p>
              <p>
                The CCPA Privacy Policy, is available at{" "}
                <a href="https://www.slalom.com/privacy-policy">
                  https://www.slalom.com/privacy-policy
                </a>
                .
              </p>
            </TextContainer>
            <Subheader id="msdp">
              Mexico Supplemental Data Protection Law Disclosures
            </Subheader>
            <TextContainer>
              <p>
                <b>1. Your rights in connection with personal information</b>
              </p>
              <p>
                <ul>
                  <li>
                    <u>Right of access</u>
                    <br />
                    You may have the right to confirm with us whether your
                    personal information is processed, and if it is, to request
                    access to that personal information including the categories
                    of personal information processed and the purpose of the
                    processing.
                  </li>
                  <li>
                    <u>Right to rectification</u>
                    <br />
                    You may have the right to rectify inaccurate or incomplete
                    personal information concerning you.
                  </li>
                  <li>
                    <u>Right of cancelation</u>
                    <br />
                    You may have the right to ask us to cancel personal
                    information concerning you and we will assess such request
                    in accordance with the relevant laws.
                  </li>
                  <li>
                    <u>Right of opposition</u>
                    <br />
                    Under certain circumstances you may have the right to oppose
                    to our processing of your personal data and we will assess
                    such request in accordance with the relevant laws.
                  </li>
                  <li>
                    <u>Right to revoke/withdraw your consent.</u>
                    <br />
                    You have the right to withdraw your consent granted to us
                    for processing your personal information and we will assess
                    such request in accordance with the relevant laws.
                  </li>
                </ul>
              </p>
              <p>
                To exercise any of the abovementioned rights, or to submit
                questions or complaints please contact us via our email address
                at <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.
              </p>
              <p>
                Your requests will be evaluated pursuant to applicable laws.
                Upon request, we can inform you about: (i) the information we
                may need to receive from you in order to identify yourself as
                well as the documents that you may need to enclose alongside
                your request; (ii) the time periods in which you will receive an
                answer from us regarding your request; (iii) how you may file
                your request, including any templates or mechanism that may be
                available (if any); (iv) the media format in which we will
                deliver the information to you (e.g. by email, CD, etc.)
              </p>
            </TextContainer>
          </PrivacyContainer>
        </Container>
      </SafeArea>
    </Root>
  )
}
